import Vue from 'vue'
import Vuex from 'vuex'
import Users from './modules/user'
import Report from './modules/report'
import Product from './modules/product'
import Stores from './modules/store'
Vue.use(Vuex)

export default new Vuex.Store({
  // add here global state/actions/mutations and register modules
  state: {
    load: false,
  },
  mutations: {
    setLoad(state, flag) { state.load = flag },
  },
  actions: {},
  modules: {
    Users,
    Report,
    Product,
    Stores,
  },
})
