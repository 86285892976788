import api from '@/api';

const state = {
  reportData: null,
  stores: [],
  invoiceTypes: [],
  voidedList: [],
  userList: [],
};

const mutations = {
  SET_REPORT_DATA(state, data) {
    state.reportData = data;
  },
  SET_STORES(state, stores) {
    state.stores = stores;
  },
  SET_INVOICE_TYPES(state, invoiceTypes) {
    state.invoiceTypes = invoiceTypes;
  },
  SET_VOIDED_LIST(state, voidedList) {
    state.voidedList = voidedList;
  },
  SET_USER_LIST(state, userList) {
    state.userList = userList;
  }
};

const actions = {
  async fetchReport({ commit }, reportParams) {
    try {
      const data = await api.getReport(reportParams);
      commit('SET_REPORT_DATA', data);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchStores({ commit }) {
    try {
      const stores = await api.getStores();
      commit('SET_STORES', stores);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchInvoiceTypes({ commit }) {
    try {
      const invoiceTypes = await api.getInvoiceTypes();
      commit('SET_INVOICE_TYPES', invoiceTypes);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchVoidedList({ commit }) {
    try {
      const voidedList = await api.getVoided();
      commit('SET_VOIDED_LIST', voidedList);
    } catch (error) {
      console.error(error);
    }
  },
  async fetchUserList({ commit }) {
    try {
      const userList = await api.getUsers();
      commit('SET_USER_LIST', userList);
    } catch (error) {
      console.error(error);
    }
  },
};

const getters = {
  reportData: state => state.reportData,
  stores: state => state.stores,
  invoiceTypes: state => state.invoiceTypes,
  voidedList: state => state.voidedList,
  userList: state => state.userList,
};

  export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
  };
