<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="copyright">
        <a href="https://www.farmaciasannicolas.com/" target="_blank" rel="noopener">Farmacias San Nicolas</a>
      </div>
    </div>
    <a class="pl-4" target="_blank" rel="noopener" style="color: #0097a2;">{{ version }}</a>
  </footer>
</template>

<script>
import { VERSION } from '@/utils/constants';

export default {
  data() {
    return {
      year: new Date().getFullYear(),
      version: VERSION
    };
  }
};
</script>

<style>
</style>