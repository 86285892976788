<template>
  <div>
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qrData: {
        AuthNumber: null,
        IssuedTimeStamp: null
      }
    };
  },
  created() {
    const hash = window.location.hash;
    if (hash.includes('?')) {
      const urlParams = new URLSearchParams(hash.split('?')[1]);
      const authNumber = urlParams.get('GenerationCode');
      const issuedTimeStamp = urlParams.get('IssuedDateTime');
      console.log('AuthNumber:', authNumber);
      console.log('IssuedTimeStamp:', issuedTimeStamp);
      if (authNumber && issuedTimeStamp) {
        this.qrData = {
          AuthNumber: authNumber,
          IssuedTimeStamp: issuedTimeStamp
        };
        console.log('QR Data:', this.qrData);
        this.$router.push({ name: 'QRSearch', params: { authNumber, issuedTimeStamp } });
      } else {
        this.$router.push({ name: 'Busqueda Manual' });
      }
    } else {
      console.error('No query parameters found in the URL hash.');
      this.$router.push({ name: 'Busqueda Manual' });
    }
  },
  methods: {
    disableRTL() {
      if (!this.$rtl.isRTL) {
        this.$rtl.disableRTL();
      }
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName('html')[0];
      root.classList.toggle('nav-open');
    }
  },
  mounted() {
    this.$watch('$route', this.disableRTL, { immediate: true });
    this.$watch('$sidebar.showSidebar', this.toggleNavOpen);
    let docClasses = document.body.classList;
    docClasses.add('white-content');
  }
};
</script>

<style lang="scss"></style>
