var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"button-with-tooltip",on:{"mouseover":_vm.showTooltip,"mouseleave":_vm.hideTooltip}},[_c(_vm.tag,{tag:"component",staticClass:"btn",class:[
      {'btn-round': _vm.round},
      {'btn-block': _vm.block},
      {'btn-icon btn-fab': _vm.icon},
      {[`btn-${_vm.type}`]: _vm.type},
      {[`btn-${_vm.size}`]: _vm.size},
      {'btn-simple': _vm.simple},
      {'btn-link': _vm.link},
      {'disabled': _vm.disabled && _vm.tag !== 'button'}
    ],attrs:{"type":_vm.tag === 'button' ? _vm.nativeType : '',"disabled":_vm.disabled || _vm.loading},on:{"click":_vm.handleClick}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._t("default")],2),(_vm.isTooltipVisible && _vm.tooltipText)?_c('div',{staticClass:"tooltip"},[_vm._v(" "+_vm._s(_vm.tooltipText)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }