import api from '@/api';
import { enableProduct } from '../../api';
//import { disableProduct } from '../../api';

const state = {
  productData: [],
  itemTypeData: [],
  unitOf: [],
  taxesData: [],
  salesTypeData: [],
  singleProduct: null, // Estado para almacenar un producto individual
};

const mutations = {
  SET_PRODUCT_DATA(state, data) {
    state.productData = Array.isArray(data) ? data : [];
  },
  SET_ITEM_TYPE_DATA(state, data) {
    state.itemTypeData = Array.isArray(data) ? data : [];
  },
  SET_UNIT_OF_DATA(state, data) {
    state.unitOf = Array.isArray(data) ? data : [];
  },
  SET_TAXES_DATA(state, data) {
    state.taxesData = Array.isArray(data) ? data : [];
  },
  SET_SALES_TYPE(state, data) {
    state.salesTypeData = Array.isArray(data) ? data : [];
  },
  SET_SINGLE_PRODUCT(state, product) { // Nueva mutación para un producto individual
    state.singleProduct = product;
  }
};

const actions = {
  async fetchProduct({ commit }) {
    try {
      const data = await api.getProduct();
      commit('SET_PRODUCT_DATA', data);
    } catch (error) {
      console.error("Error fetching product data:", error);
    }
  },
  async fetchItemType({ commit }) {
    try {
      const data = await api.getItemType();
      commit('SET_ITEM_TYPE_DATA', data);
    } catch (error) {
      console.error("Error fetching item type data:", error);
    }
  },
  async fetchUnitOf({ commit }) {
    try {
      const data = await api.getUnitOf();
      commit('SET_UNIT_OF_DATA', data);
    } catch (error) {
      console.error("Error fetching unit of data:", error);
    }
  },
  async fetchTaxes({ commit }) {
    try {
      const data = await api.getTaxes();
      commit('SET_TAXES_DATA', data);
    } catch (error) {
      console.error("Error fetching taxes data:", error);
    }
  },
  async fetchSalesType({ commit }) {
    try {
      const data = await api.getSalesType();
      commit('SET_SALES_TYPE', data);
    } catch (error) {
      console.error("Error fetching sales type data:", error);
    }
  },
  async fetchProductById({ commit }, code) {
    try {
      const product = await api.getProductById(code); // Llama a la función que definimos en api.js

      commit('SET_SINGLE_PRODUCT', product); // Usa la nueva mutación para establecer el producto individual
    } catch (error) {
      console.error('Error fetching product by ID:', error);
    }
  },
  async saveProduct({ commit }, product) {
    try {
      await api.saveProduct(product);

      //alert('');
    } catch (error) {
      console.error('Error saving product:', error);
      throw error;
    }
  },
  async disableProduct({ commit }, code) {
    try {

      await api.disableProduct(code);
    //  alert('Producto deshabilitado exitosamente');
    } catch (error) {
      console.error('Error disabling product product.js:', error);
      throw error;
    }
  },
  async enableProduct({ commit }, code) {
    try {
      await api.enableProduct(code);
      //alert('Producto habilitado exitosamente');
    } catch (error) {
      console.error('Error enabling product:', error);
      throw error;
    }
  }
};

const getters = {
  productData: state => state.productData,
  itemTypeData: state => state.itemTypeData,
  unitOf: state => state.unitOf,
  taxesData: state => state.taxesData,
  salesTypeData: state => state.salesTypeData,
  singleProduct: state => state.singleProduct, // Nuevo getter para un producto individual
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
