import axios from "axios";
import { API_URL } from "@/utils/constants";

const getStores = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetStores`);
    return data;
  } catch (error) {
    return error;
  }
};
export const getStoreById = async (code) => {
  try {
    const response = await axios.get(
      `${API_URL}/Store/GetStoreById?storeId=${code}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching store by ID:", error);
    throw error;
  }
};

const getInvoiceTypes = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetInvoiceTypes`);
    return data;
  } catch (error) {
    return error;
  }
};

const getVoided = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/Voided`);
    return data;
  } catch (error) {
    return error;
  }
};

//TODO: Revisar la utilidad o no de este endpoint
const getUsers = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetUser`);
    return data;
  } catch (error) {
    return error;
  }
};

const getProducts = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetAllProducts`);
    return data;
  } catch (error) {
    return error;
  }
};

const getReport = async (reportParams) => {
  try {
    const filteredParams = Object.fromEntries(
      Object.entries(reportParams).filter(([_, v]) => v != null && v !== "")
    );
    const queryParams = new URLSearchParams(filteredParams).toString();
    const { data } = await axios.get(`${API_URL}/Report/Report?${queryParams}`);
    return data;
  } catch (error) {
    return error;
  }
};
const getEconomicActivity = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetEconomicActivity`);

    return data;
  } catch (error) {
    return error;
  }
};

const GetDistricts = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetDistricts`);

    return data;
  } catch (error) {
    return error;
  }
};

const GetEstablishmentType = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetEstablishmentType`);

    return data;
  } catch (error) {
    return error;
  }
};

const GetDepartments = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetDepartments`);

    return data;
  } catch (error) {
    return error;
  }
};

const GetTaxes = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetTaxesList`);

    return data;
  } catch (error) {
    return error;
  }
};

const GetUnits = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetUnitList`);

    return data;
  } catch (error) {
    return error;
  }
};
// TODO: Revisar la utilidad o no de este endpoint
const getUsersAll = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/Users/UsersAll`);
    return data;
  } catch (error) {
    return error;
  }
};

//#region Product
const getProduct = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/Product/GetProducts`);
    return data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error; // Re-lanza el error para que pueda ser capturado en la acción
  }
};

const getItemType = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetItemType`);
    return data;
  } catch (error) {
    return error;
  }
};
const getUnitOf = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetUnitOf`);
    return data;
  } catch (error) {
    return error;
  }
};
const getTaxes = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetTaxes`);
    return data;
  } catch (error) {
    return error;
  }
};

const getSalesType = async () => {
  try {
    const { data } = await axios.get(`${API_URL}/General/GetSalesType`);
    return data;
  } catch (error) {
    return error;
  }
};

const GetInvoiceList = async (invoice) => {
  try {
    let token = sessionStorage.getItem("authToken");
    const { data } = await axios.post(
      `${API_URL}/Invoice/GetInvoiceList`,
      invoice,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error en la API de facturas:", error);
    throw error;
  }
};

const PostCertify = async (dte) => {
  try {
    let token = sessionStorage.getItem("authToken");
    const { data } = await axios.post(`${API_URL}/Invoice/Certify`, dte, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    console.error("Error invoice api:", error);
    throw error;
  }
};
const PostCertifyPreview = async (dte) => {
  try {
    const { data } = await axios.post(
      `${API_URL}/Invoice/GenerateInvoicePreview`,
      dte
    );
    return data;
  } catch (error) {
    console.error("Error invoice api:", error);
    throw error;
  }
};

export const getProductById = async (code) => {
  try {
    const response = await axios.get(`${API_URL}/Product/GetProductsId`, {
      params: {
        code: code,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching product by ID:", error);
    throw error;
  }
};
//#endregion

const getInvoice = async (authNumber) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/Invoice/GetInvoicePrintedFormat?authNumber=${authNumber}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

const sendEmail = async (args) => {
  try {
    const res = await axios.post(
      `${API_URL}/Invoice/SendMailByGenerationCode`,
      {
        ...args,
      }
    );
    if (res.data.success === false) {
      throw new Error(res.data.message);
    }

    return res;
  } catch (error) {
    console.error(error);

    throw error;
  }
};

const GetProductsInvoiceByGenCode = async (codGen) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/Invoice/GetProductsInvoiceByGenCode?generationCode=${codGen}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

const GeneratePDFtoBase64 = async (codGen) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/General/GeneratePDFtoBase64?codGen=${codGen}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

const GeneratePDFtoBase64Consult = async (codGen) => {
  try {
    const { data } = await axios.get(
      `${API_URL}/General/GeneratePDFtoBase64Consult?codGen=${codGen}`
    );
    return data;
  } catch (error) {
    return error;
  }
};

export default {
  getEconomicActivity,
  GetDistricts,
  GetEstablishmentType,
  GetDepartments,
  getReport,
  getStores,
  getInvoiceTypes,
  getVoided,
  getUsers,
  getProducts,
  getProduct,
  getItemType,
  getUnitOf,
  getTaxes,
  getSalesType,
  getProductById,
  GetTaxes,
  GetUnits,
  GetInvoiceList,
  PostCertify,
  getInvoice,
  sendEmail,
  PostCertifyPreview,
  GetProductsInvoiceByGenCode,
  GeneratePDFtoBase64,
  getStoreById,
  GeneratePDFtoBase64Consult,
  getUsersAll,
};
