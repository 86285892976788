import api from '@/api';

const state = () => ({
  sessionUserRol: [],
  sessionUserCode: '',
  userStores: [], //Stores
  tokenSession: '',
})

const actions = {
  async getToken({ commit }, userData) {
    try {
     return await api.getToken(userData);
    } catch (error) {
      console.error('Error get Token:', error);
      throw error;
    }
  },
}

const mutations = {
  setSessionUserRol(state, rol) { state.sessionUserRol = rol },
  setSessionUserCode(state, code) { state.sessionUserCode = code },
  setTokenSession(state, token) { state.tokenSession = token }
}

const getters = {
  userCod: state => state.sessionUserCode,
  rol: state => state.sessionUserRol,
  token: state => state.tokenSession,
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}