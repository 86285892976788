<!-- src/components/BaseButton.vue -->
<template>
  <div
    class="button-with-tooltip"
    @mouseover="showTooltip"
    @mouseleave="hideTooltip"
  >
    <component
      :is="tag"
      :type="tag === 'button' ? nativeType : ''"
      :disabled="disabled || loading"
      @click="handleClick"
      class="btn"
      :class="[
        {'btn-round': round},
        {'btn-block': block},
        {'btn-icon btn-fab': icon},
        {[`btn-${type}`]: type},
        {[`btn-${size}`]: size},
        {'btn-simple': simple},
        {'btn-link': link},
        {'disabled': disabled && tag !== 'button'}
      ]"
    >
      <!-- Spinner se muestra cuando está en loading -->
      <i v-if="loading" class="fas fa-spinner fa-spin"></i>
      
      <!-- Contenido del botón -->
      <slot v-else></slot>
    </component>
    <div v-if="isTooltipVisible && tooltipText" class="tooltip">
      {{ tooltipText }}
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    tag: {
      type: String,
      default: "button",
      description: "Button html tag"
    },
    round: Boolean,
    icon: Boolean,
    block: Boolean,
    loading: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: "default",
      description: "Button type (primary|secondary|danger etc)"
    },
    nativeType: {
      type: String,
      default: "button",
      description: "Button native type (e.g button, input etc)"
    },
    size: {
      type: String,
      default: "",
      description: "Button size (sm|lg)"
    },
    simple: {
      type: Boolean,
      description: "Whether button is simple (outlined)"
    },
    link: {
      type: Boolean,
      description: "Whether button is a link (no borders or background)"
    },
    tooltipText: {
      type: String,
      default: "",
      description: "Text for the tooltip"
    }
  },
  data() {
    return {
      isTooltipVisible: false
    };
  },
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
    showTooltip() {
      this.isTooltipVisible = true;
    },
    hideTooltip() {
      this.isTooltipVisible = false;
    }
  }
};
</script>

<style scoped>
.button-with-tooltip {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  bottom: 125%; /* Posición encima del botón */
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.button-with-tooltip:hover .tooltip {
  opacity: 1;
}
</style>
