import api from '@/api';
import store from '..';


const state = {
  storeData: [],
  singleStore: null,
  establishmentTypeData: [],
};

const mutations = {
  SET_STORE_DATA(state, data) {
    state.storeData = Array.isArray(data) ? data : [];
  },
  SET_SINGLE_STORE(state, store) {
    state.singleStore = store;
  },
  SET_ESTABLISHMENT_TYPE(state, data) {
    state.establishmentTypeData = Array.isArray(data) ? data : [];
  },
};

const actions = {
  async fetchStore({ commit }) {
    try {
      const data = await api.getStores();
      commit('SET_STORE_DATA', data);
    } catch (error) {
      console.error("Error fetching store data:", error);
    }
  },
  async fetchSingleStore({ commit }, id) {
    try {
      const data = await api.getStoreById(id);
      commit('SET_SINGLE_STORE', data);
    } catch (error) {
      console.error("Error fetching single store data:", error);
    }
  },
  async fetchEstablishmentType({ commit }) {
    try {
      const data = await api.GetEstablishmentType();
      commit('SET_ESTABLISHMENT_TYPE', data);
    } catch (error) {
      console.error("Error fetching establishment type data:", error);
    }
  },
  async saveStore({ commit }, store) {
    try {
      await api.saveStore(store);
      //store.dispatch('fetchStore');
    } catch (error) {
      console.error("Error creating store:", error);
    }
  },
  async changeStatusStore({ commit }, store) {
    try {
      await api.changeStatusStore(store);

    } catch (error) {
      console.error("Error changing status store:", error);
    }
  }
};

const getters = {
  storeData: state => state.storeData,
  singleStore: state => state.singleStore,
  establishmentTypeData: state => state.establishmentTypeData,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
