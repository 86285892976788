<template>
  <nav class="navbar navbar-expand-lg navbar-absolute" :class="{ 'bg-white': showMenu, 'navbar-transparent': !showMenu }">
    <div class="container-fluid">
      <div class="navbar-wrapper d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center">
          <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
            <button type="button" class="navbar-toggler" aria-label="Navbar toggle button" @click="toggleSidebar">
              <span class="navbar-toggler-bar bar1"></span>
              <span class="navbar-toggler-bar bar2"></span>
              <span class="navbar-toggler-bar bar3"></span>
            </button>
          </div>
          <a class="navbar-brand ml-2">{{ routeName }}</a>
        </div>
        <div class="navbar-logo-container">
          <img src="../../assets/fasani-logo-nav.png" class="navbar-brand-img" alt="Farmacias San Nicolas" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Modal from '@/components/Modal';

export default {
  components: {
    Modal
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    }
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: ''
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>

<style lang="scss">
.navbar-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbar-logo-container {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

.navbar-brand-img {
  max-width: 100px;
}

.navbar-brand {
  margin-left: 10px;
}
</style>
