import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
import NotFound from "@/pages/NotFoundPage.vue";

const QRSearch = () => import(/* webpackChunkName: "common" */ "@/pages/QRSearch.vue");
const ManualSearch = () => import(/* webpackChunkName: "common" */ "@/pages/ManualSearch.vue");
const routes = [
  {
    path: "/qr-search",
    name: "QRSearch",
    props: true,
    component: QRSearch
  },
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/manual-search",
    children: [
      {
        path: "manual-search",
        name: "Busqueda Manual",
        component: ManualSearch,
      }
    ]
  },
  { path: "*", component: NotFound },
];

export default routes;
