<template>
  <div class="fixed-plugin" v-click-outside="closeDropDown">
    <div class="dropdown show-dropdown" :class="{show: isOpen}">
      <a data-toggle="dropdown" class="settings-icon">
        <i class="fa fa-cog fa-2x" @click="toggleDropDown"> </i>
      </a>
      <ul class="dropdown-menu" :class="{show: isOpen}">
        <li class="header-title">Background</li>
        <li class="adjustments-line text-center color-change">
          <span class="color-label">LIGHT MODE </span>
          <span class="badge light-badge mr-2" @click="toggleMode('white')"></span>
          <span class="badge dark-badge ml-2" @click="toggleMode('dark')"></span>
          <span class="color-label"> DARK MODE</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    props: {
      backgroundColor: String,
    },
    data() {
      return {
        isOpen: false,
        sidebarColors: [
          { color: 'primary', active: false, value: 'primary' },
          { color: 'vue', active: true, value: 'vue' },
          { color: 'info', active: false, value: 'blue' },
          { color: 'success', active: false, value: 'green' }
        ]
      }
    },
    methods: {
      toggleDropDown() {
        this.isOpen = !this.isOpen
      },
      closeDropDown() {
        this.isOpen = false
      },
      toggleList(list, itemToActivate) {
        list.forEach((listItem) => {
          listItem.active = false
        });
        itemToActivate.active = true
      },
      changeSidebarBackground(item) {
        this.$emit('update:backgroundColor', item.value)
        this.toggleList(this.sidebarColors, item)
      },
      toggleMode(type) {
        let docClasses = document.body.classList;
        if(type === 'white') {
          docClasses.add('white-content')
        } else {
          docClasses.remove('white-content')
        }
      }
    }
  }
</script>
<style scoped lang="scss">
  @import "~@/assets/sass/black-dashboard/custom/variables";
  .settings-icon {
    cursor: pointer;
  }
  .badge-vue {
    background-color: $vue;
  }
</style>
