/*
 =========================================================
 * Vue Black Dashboard - v1.1.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard
 * Copyright 2018 Creative Tim (http://www.creative-tim.com)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

import Vue from "vue";
import VeeValidate from "vee-validate";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import App from "./App";
// TIP: change to import router from "./router/starterRouter"; to start with a clean layout
import router from "./router/index";
import { Tooltip } from "@mui/material";
import "vue-select/dist/vue-select.css";
import Multiselect from "vue-multiselect"; // Importa el componente
import BlackDashboard from "./plugins/blackDashboard";
import vSelect from "vue-select";
import store from "./store";
import $ from "jquery"; // Importa jQuery desde node_modules
window.jQuery = $; // Hace jQuery global en el contexto de window
window.$ = $;
import "datatables.net-bs4";

import "vue-multiselect/dist/vue-multiselect.min.css";

// Configura VeeValidate
Vue.use(VeeValidate);

Vue.use(BlackDashboard);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(Tooltip);
Vue.component("v-select", vSelect);
Vue.component("multiselect", Multiselect);
// register globally

/* eslint-disable no-new */

router.beforeEach((to, from, next) => {
  next();
});
new Vue({
  router,
  store,
  Tooltip,
  render: (h) => h(App),
}).$mount("#app");
